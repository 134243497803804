"use client";

import Image from "next/image";
import React from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
import { VideoThumbnailProps } from "@/TS/Interfaces/global_interfaces";
function VideoThumbnail(props: VideoThumbnailProps) {
  return <div className={twMerge(`relative group flex flex-col justify-center items-center overflow-hidden rounded-sm bg-white p-1 ${props.className}`)} data-sentry-component="VideoThumbnail" data-sentry-source-file="video-thumbnail.tsx">
      <div className={"flex justify-center items-center aspect-video relative w-full h-full overflow-hidden"}>
        <Image className={props.classNamePicture} src={`https://img.youtube.com/vi/${props.youtubeId}/hqdefault.jpg`} alt={props.alt ?? ""} width={600} height={500} data-sentry-element="Image" data-sentry-source-file="video-thumbnail.tsx" />
      </div>
      <Icon icon={"player"} className={"video-thumbnail-icon !w-16 !h-16 group-hover:!w-20 group-hover:!h-20"} data-sentry-element="Icon" data-sentry-source-file="video-thumbnail.tsx" />
    </div>;
}
export default VideoThumbnail;