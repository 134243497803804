"use client";

import Image from "next/image";
import React from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
import { HomeReasonCardProps } from "@/TS/Interfaces/global_interfaces";
function HomeReasonCard(props: HomeReasonCardProps) {
  return <div className={twMerge(`flex flex-col font-sora ${props.className}`)} data-sentry-component="HomeReasonCard" data-sentry-source-file="home-reason-card.tsx">
      <div className={`w-full px-2`}>
        <Image src={`${props.img}`} width={500} height={500} className={"w-full rounded"} alt={props.title} data-sentry-element="Image" data-sentry-source-file="home-reason-card.tsx" />
      </div>
      <div className={twMerge(`flex flex-col justify-center items-center -mt-5 mb-2`)}>
        <span className={twMerge(`md:text-xl font-bold px-4 py-1 text-white ${props.titleClassName}`)}>
          {props.title}
        </span>
      </div>
      <div className={`flex flex-col justify-center text-center text-sm md:text-base`}>
        <span className={"text-xl"}>{props.subtitle}</span>
      </div>
    </div>;
}
export default HomeReasonCard;