"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import useMobile from "@/hooks/use-mobile";
import { Club, ClubRead, SidebarContextProps } from "@/TS/Interfaces/global_interfaces";
export interface ClubMapContextProps {
  clubMapValues?: ClubMapValues;
  setClubMapValues?: Dispatch<SetStateAction<string | ClubMapValues>>;
  isShown?: () => boolean;
  resetClubMapValues?: any;
}
export interface ClubMapValues {
  x?: number;
  y?: number;
  club?: ClubRead;
  isMarkerHover: boolean;
  isPopinHover: boolean;
}
export const clubMapContext = createContext<ClubMapContextProps | null>(null);
function ClubMapContextProvider({
  children
}): ReactNode {
  const initialState = {
    x: null,
    y: null,
    club: null,
    isMarkerHover: false,
    isPopinHover: false
  };
  const [clubMapValues, setClubMapValues] = useState<ClubMapValues>(initialState);
  function isShown(): boolean {
    return clubMapValues.isMarkerHover || clubMapValues.isPopinHover;
  }
  function resetClubMapValues(): void {
    setClubMapValues(initialState);
  }
  return <clubMapContext.Provider value={{
    clubMapValues,
    setClubMapValues,
    isShown,
    resetClubMapValues
  }} data-sentry-element="unknown" data-sentry-component="ClubMapContextProvider" data-sentry-source-file="club-map-context.tsx">
      {children}
    </clubMapContext.Provider>;
}
export default ClubMapContextProvider;