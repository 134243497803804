"use client";

import { GridAlgorithm, type Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import SearchClubItem from "@/app/(main)/(public)/clubs/search-club-item";
import MapMarker from "@/app/(main)/_public_components/map-marker";
import Dump from "@/components/DEBUG/dump";
import { clubMapContext } from "@/context/club-map-context";
import { sidebarContext } from "@/context/sidebar-context";
import useMobile from "@/hooks/use-mobile";

/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
function ClusteredClubMarkers(props) {
  const isMobile = useMobile();
  const clubs = useMemo(() => {
    return props.clubs;
  }, [props.clubs]);
  const locations = clubs.map(club => {
    return {
      lat: club?.postalAddress?.latitude,
      long: club?.postalAddress?.longitude
    };
  });
  const [markers, setMarkers] = useState<{
    [key: string]: Marker;
  }>({});
  const {
    val,
    setVal,
    setIsHide,
    setJsxValue
  } = useContext(sidebarContext);
  const {
    clubMapValues,
    setClubMapValues,
    isShown
  } = useContext(clubMapContext);
  const map = useMap();
  const clusterer = useRef<MarkerClusterer | null>(null);
  useEffect(() => {
    if (!map || clusterer.current) return;
    clusterer.current = new MarkerClusterer({
      map
    });
  }, [map]);
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);
  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;
    setMarkers(prev => {
      if (marker) {
        return {
          ...prev,
          [key]: marker
        };
      } else {
        const newMarkers = {
          ...prev
        };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };
  const handleMarkerClick = useCallback((event, club) => {
    if (isMobile && club) {
      setClubMapValues({
        ...clubMapValues,
        club: club
      });
      setVal(<SearchClubItem club={club} mobileSide={true} />);
      setIsHide(false);
    }
  }, [isMobile, clubMapValues, setClubMapValues, setVal, setIsHide]);
  const handleMarkerEnter = (event, club) => {
    setClubMapValues({
      ...clubMapValues,
      x: isMobile ? null : event.clientX,
      y: isMobile ? null : event.clientY,
      club: club,
      isMarkerHover: isMobile ? false : true,
      isPopinHover: false
    });
  };
  const handleMarkerLeave = () => {
    setClubMapValues({
      ...clubMapValues,
      isMarkerHover: false
    });
  };
  const renderClubMapMarkers = useMemo(() => {
    return clubs.map(club => club?.postalAddress?.latitude && club?.postalAddress?.longitude && <MapMarker ref={marker => setMarkerRef(marker, club.slug)} key={club.slug} club={club} position={{
      lat: Number(club?.postalAddress?.latitude),
      lng: Number(club?.postalAddress?.longitude)
    }} onClick={handleMarkerClick} onMouseEnter={handleMarkerEnter} onMouseLeave={handleMarkerLeave} setMarkerRef={setMarkerRef} />);
  }, [clubs, isMobile]);
  return <>{renderClubMapMarkers}</>;
}
export default React.memo(ClusteredClubMarkers);