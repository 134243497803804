"use client";

import { APIProvider, Map } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import ClusteredClubMarkers from "@/app/(main)/_public_components/clustered-club-markers";
import MapCookieFallback from "@/app/(main)/_public_components/map-cookie-fallback";
import { getSearchClubs } from "@/app/api/dynabuy-back/api-search-club";
import useCitron from "@/hooks/use-citron";
import { ClubItem } from "@/TS/Interfaces/global_interfaces";
function HomeMap({
  className
}: {
  className?: string;
}) {
  const googleMapApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
  const [clubs, setClubs] = useState<ClubItem[]>([]);
  const {
    isMapAccepted
  } = useCitron();
  useEffect((): void => {
    const initPage = async () => {
      const clubs = await getSearchClubs();
      // console.log(clubs);
      setClubs(clubs);
    };
    initPage();
  }, []);
  return <APIProvider apiKey={googleMapApiKey}
  // onLoad={() => console.log("Maps API has loaded.")}
  data-sentry-element="APIProvider" data-sentry-component="HomeMap" data-sentry-source-file="home-map.tsx">
      {!isMapAccepted && <MapCookieFallback className={"md:mb-0 mx-0 md:mx-8"}></MapCookieFallback>}
      <Map mapId={"club-search-map"} defaultZoom={5} defaultCenter={{
      lat: 46.9,
      lng: 2
    }} mapTypeControl={false} fullscreenControl={false} streetViewControl={false} zoomControl={false} className={twMerge(`pointer-events-none ${className}`)} data-sentry-element="Map" data-sentry-source-file="home-map.tsx">
        {clubs && <ClusteredClubMarkers clubs={clubs} gridSize={30} />}
      </Map>
    </APIProvider>;
}
export default HomeMap;