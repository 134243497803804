"use client";

import { AdvancedMarker, AdvancedMarkerAnchorPoint } from "@vis.gl/react-google-maps";
import React, { forwardRef, memo } from "react";
const MapMarker = forwardRef(function MapMarker(props: any, ref) {
  return <>
      <AdvancedMarker ref={ref} position={props.position} clickable={true} className="group relative hover:z-20" onMouseEnter={event => props.onMouseEnter(event, props.club)}
    // onMouseLeave={(event) => props.onMouseLeave(event, props.club)}
    collisionBehavior={google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY} onClick={event => props.onClick(event, props.club)} anchorPoint={AdvancedMarkerAnchorPoint.CENTER}>
        <div className={`map-marker format-${props.club.format}`}></div>
      </AdvancedMarker>
    </>;
});
export default memo(MapMarker);