import {
  ClubRoleEnum,
  ClubStatusEnum,
  ClubTypeEnum,
} from "@/TS/Enums/global_enums";
import { MyClubRead } from "@/TS/Interfaces/global_interfaces";

export const canAccessClub = (club: MyClubRead) => {
  return (
    club.type === ClubTypeEnum.CLUB ||
    (club.status === ClubStatusEnum.COMPLETED &&
      club.type === ClubTypeEnum.REGISTRATION)
  );
};

export const roleLabelElements = (club: MyClubRead) => {
  switch (club.role) {
    case ClubRoleEnum.PRESIDENT:
      return {
        label: "Président",
        color: "bg-primary",
      };
    case ClubRoleEnum.MEMBER:
      return {
        label: "Membre",
        color: "bg-info-blue",
      };
  }
  return null;
};

export const getClubStatusElements = (club: MyClubRead) => {
  switch (club.type) {
    case ClubTypeEnum.CLUB:
      return {
        label: "Club actif",
        icon: "check-simple",
        color: "text-success2",
        bgColor: "bg-success",
      };
    case ClubTypeEnum.PARTICIPATION:
      switch (club.status) {
        case ClubStatusEnum.PENDING:
          return {
            label: "Demande de participation envoyée",
            icon: "check-simple",
            color: "text-warning2",
            bgColor: "bg-warning",
          };
        case ClubStatusEnum.ACCEPTED:
          return {
            label: "Demande de participation acceptée",
            icon: "check-simple",
            color: "text-info-blue",
            bgColor: "bg-light-blue",
          };
        case ClubStatusEnum.COMPLETED:
          return {
            label: "Réunion découverte réalisée",
            icon: "check-simple",
            color: "text-success2",
            bgColor: "bg-success",
          };
      }
    case ClubTypeEnum.REGISTRATION:
      switch (club.status) {
        case ClubStatusEnum.PRESIDENT_REFUSED:
          return {
            label: "Demande d'adhésion refusée",
            icon: "cross",
            color: "text-danger2",
            bgColor: "bg-danger",
          };
        case ClubStatusEnum.PENDING:
          return {
            label: "Demande d'adhésion en attente",
            icon: "time",
            iconClassName: "m1",
            iconSize: 25,
            color: "text-warning2",
            bgColor: "bg-warning",
          };
        case ClubStatusEnum.COMPLETED:
          return {
            label: "Adhésion validée",
            icon: "check-simple",
            color: "text-success2",
            bgColor: "bg-success",
          };
      }
    case ClubTypeEnum.INVITATION:
      switch (club.status) {
        case ClubStatusEnum.ACCEPTED:
          return {
            label: "Demande de participation acceptée",
            icon: "check-simple",
            color: "text-info-blue",
            bgColor: "bg-light-blue",
          };
      }
  }
  return null;
};

export const clubFormats = {
  weekly: {
    label: "Intense",
    icon: "rocket",
    color: "text-primary",
    bgColor: "bg-primary",
    borderColor: "border-primary",
    description: "4 réunions / mois",
    description2: "Toutes les semaines",
  },
  fortnightly: {
    label: "Développement",
    icon: "train",
    color: "text-warning2",
    bgColor: "bg-warning2",
    borderColor: "border-warning2",
    description: "2 réunions / mois",
    description2: "Toutes les 2 semaines",
  },
  monthly: {
    label: "Convivial",
    icon: "ship",
    color: "text-success2",
    bgColor: "bg-success2",
    borderColor: "border-success2",
    description: "1 réunion / mois",
    description2: "Toutes les 4 semaines",
  },
  // TODO IMPORTANT !
};
