import Link from "next/link";
import { className } from "postcss-selector-parser";
import { twMerge } from "tailwind-merge";
import Button from "@/components/button";
import Dump from "@/components/DEBUG/dump";
import Icon from "@/components/icon";
import ProfilePicture from "@/components/user/profile-picture";
import useMobile from "@/hooks/use-mobile";
import { clubFormats } from "@/lib/club-constants";
import { formatter } from "@/lib/formatter";
import { SearchClubItemProps } from "@/TS/Interfaces/global_interfaces";
export default function SearchClubItem(props: SearchClubItemProps) {
  let clubFormatArray = clubFormats[props.club.format];
  const isMobile = useMobile();
  return <div {...props.onMouseEnter && {
    onMouseEnter: event => props.onMouseEnter(event, props.club)
  }} {...props.onMouseLeave && {
    onMouseLeave: event => props.onMouseLeave(event, props.club)
  }} className={twMerge(`w-full md:w-1/4 ${props.mobileSide ? "" : "px-2"} p-4 pb-8  relative ${props.className}`)} data-sentry-component="SearchClubItem" data-sentry-source-file="search-club-item.tsx">
      <div className={`flex flex-col items-center relative rounded ${props.mobileSide ? "" : "shadow"} h-full bg-info`}>
        {props.club.new == true && <div className={"absolute right-8 text-white text-sm"}>
            <div className={"bg-info-blue p-2"}>NOUVEAU</div>
            <div className={"new-label-triangles"}>
              <div></div>
            </div>
          </div>}
        <div className={`min-h-24 md:min-h-12 2xl:min-h-24 mt-8 px-4 ${props.mobileSide ? "mt-24" : ""}`}>
          <h3 className={"text-2xl md:text-xl 2xl:text-2xl uppercase font-bold text-center"}>
            {props.club.displayName}
          </h3>
        </div>
        <div className={"flex flex-col justify-center font-bold mb-12 px-4 text-center grow md:text-sm 2xl:text-base"}>
          <div className={"flex justify-center"}>
            <div className={`rounded-full m-1 py-1 px-4 text-white uppercase ${clubFormatArray?.bgColor}`}>
              FORMAT {clubFormatArray?.label}
            </div>
          </div>
          <div className={"flex flex-wrap justify-center px-4"}>
            <div className={`rounded-full m-1 py-1 px-4 text-white bg-member-blue`}>
              <Icon icon={"members"} size={25} className={"mr-2"} data-sentry-element="Icon" data-sentry-source-file="search-club-item.tsx" />
              {props.club.countActiveMembers} membre
              {props.club.countActiveMembers > 1 ? "s" : ""}
            </div>
            {/*<div*/}
            {/*  className={`rounded-full m-1 py-1 px-4 bg-light-blue text-dark-blue`}*/}
            {/*>*/}
            {/*  <Icon icon={"stats"} size={15} className={"mr-2"} />*/}
            {/*  CA : {formatter.integer(props.club.countSales)} €*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={`flex flex-col items-center w-full ${props.mobileSide ? "bg-info rounded" : "bg-white"} grow`}>
          <ProfilePicture src={props.club?.president?.avatar?.urls?.avatar_md} firstName={props.club?.president?.firstName} lastName={props.club?.president?.lastName} className={"-mt-8"} size={72} data-sentry-element="ProfilePicture" data-sentry-source-file="search-club-item.tsx" />
          <div className={"text-center flex flex-col justify-between grow"}>
            <div>
              <p className={"mt-2"}>Présidé par</p>
              <p className={"font-bold mb-6"}>
                <span>
                  {props.club?.president?.firstName}{" "}
                  <span className={"uppercase"}>
                    {props.club?.president?.lastName}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={"flex flex-col justify-end items-center w-full bg-white rounded-b pt-6 md:pt-0 2xl:pt-6"}>
          <div className={"font-bold"}>
            <div className={"mb-6"}>
              <Link href={"/club/" + props.club.slug} {...(props.onMouseEnter || isMobile) && {
              onClick: () => props.onRedirect()
            }} data-sentry-element="Link" data-sentry-source-file="search-club-item.tsx">
                <Button variant={"unfilled"} data-sentry-element="Button" data-sentry-source-file="search-club-item.tsx">Découvrir le club</Button>
              </Link>
            </div>
          </div>
        </div>
        {/*<Dump>{props.club}</Dump>*/}
      </div>
    </div>;
}