"use client";

import React from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
import { HomeKeyDataProps } from "@/TS/Interfaces/global_interfaces";
function HomeKeyData(props: HomeKeyDataProps) {
  return <div className={"bg-white md:w-1/4 2xl:w-1/5 mx-8 py-6 px-4 mt-8 md:-mt-12 rounded shadow-lg text-center"} data-sentry-component="HomeKeyData" data-sentry-source-file="home-key-data.tsx">
      <div className={"flex items-center justify-center mb-3"}>
        <Icon icon={props.icon} size={props.iconSize ?? 34} className={"text-primary mr-3"} data-sentry-element="Icon" data-sentry-source-file="home-key-data.tsx" />
        <p className={"font-bold mx-4 text-[2.5rem] md:max-xl:text-2xl text-nowrap font-sora"}>
          {props.children}
        </p>
      </div>
      <p>{props.subtitle}</p>
    </div>;
}
export default HomeKeyData;