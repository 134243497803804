"use client";

import useEmblaCarousel from "embla-carousel-react";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { EmblaCarouselProps } from "@/TS/Interfaces/global_interfaces";
import { NextButton, PrevButton, usePrevNextButtons } from "./embla-carousel-arrow-buttons";
import { DotButton, useDotButton } from "./embla-carousel-dot-button";
export function EmblaCarousel(props: EmblaCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(props.options);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi);
  const {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick
  } = useDotButton(emblaApi);
  return <div className="embla" ref={emblaRef} data-sentry-component="EmblaCarousel" data-sentry-source-file="slider.tsx">
      <div className={twMerge(`embla__container ${props.containerClassName}`)}>
        {React.Children.map(props.children, (child, index) => <div className={`embla__slide ${index === selectedIndex ? "is-selected" : "is-not-selected"}`}>
            {child}
          </div>)}
      </div>
      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} data-sentry-element="PrevButton" data-sentry-source-file="slider.tsx" />
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => <DotButton key={index} onClick={() => onDotButtonClick(index)} className={"embla__dot".concat(index === selectedIndex ? " embla__dot--selected" : "")} />)}
          </div>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} data-sentry-element="NextButton" data-sentry-source-file="slider.tsx" />
        </div>
      </div>
    </div>;
}